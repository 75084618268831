.popup {
    // .popup__gallery

    &__gallery {
        height: 100%;
        // .popup__gallery-stone

        &-stone {
        }

        .popup__button-close {
            z-index: 5;
        }

        &-overlay {
            display: none;

            &._active {
                display: block;
            }

            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            z-index: 2;
            cursor: pointer;
        }
    }

    // .popup__button

    &__button {
        // .popup__button-close

        &-close {
        }
    }

    // .popup__slider

    &__slider {
        height: 100%;
        max-height: 90vh;
        // .popup__slider-direction

        &-direction {
            z-index: 5;
        }

        // .popup__slider-left

        &-left {
        }

        // .popup__slider-right

        &-right {
        }
    }

    // .popup__category

    &__category {
        z-index: 5;

        // .popup__category-item

        &-item {
        }

        .swiper-wrapper {
            & > :not(:last-child) {
                margin-right: 10px;
            }
        }

        .swiper-slide {
            width: auto !important;
        }
    }

    .swiper-stone,
    .swiper-brick,
    .swiper-mix {
        height: calc(100% - 60px);
        max-height: 90vh;

        @media (max-width: 800px) {
            height: calc(100% - 35px);
        }

        .swiper-wrapper {
            height: 100%;
            max-height: 90vh;
        }

        // .swiper-slide

        .swiper-slide {
            height: 100%;
            max-height: 90vh;

            img {
                height: 100%;
                max-height: 90vh;
                object-fit: cover;
                width: 100%;

                @media (max-width: 800px) {
                }
            }
        }
    }

    .pagination {
        display: none;
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        z-index: 5;
        overflow-x: auto;

        span {
            width: 12px;
            height: 12px;
            flex: 0 0 12px;
            background-color: #fff;
            opacity: 1 !important;
            margin: 0 7px 0 0 !important ;

            &:last-of-type {
                margin: 0 !important;
            }

            border: 1px solid #08c4ee;

            &.swiper-pagination-bullet-active {
                background-color: #08c4ee;
            }
        }

        @media (max-width: 800px) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
