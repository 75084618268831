.header{
  display: flex;
  align-items: center;
  height: 119px;
  background: transparent;
  position: relative;
  z-index: 1;
  margin: 0 0 23px 0;
  &__container {
    position: relative;
    display: flex;
    align-items: center;
  }
  &__logo {
    position: absolute;
    top: 50%;
    left: -220px;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 1900px) {
  .header {
    &__logo {
      font-size: 20px;
      left: 0;
    }
  }
}

@media screen and (max-width: 1390px) {
  .header {
    &__logo {
      left: 3%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    background: white;
    height: 100px;
  }
}
