.overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 0;
  cursor: pointer;

  &_hidden {
    display: none;
  }
}
