.menu {
  //width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  &__item {
    font-size: 16px;
    color: #000;
    padding: 20px;
    &:hover {
      color: #08C4EE;
    }
  }
}

@media screen and (max-width: 1900px) {
  .menu {
    //width: 45%;
    //margin: 0 0 0 120px;
    &__item {
      padding: 20px 15px;
    }
  }
}

@media screen and (max-width: 1650px) {
  .menu {
    width: 35%;
    //margin: 0 0 0 200px;
  }
}

@media screen and (max-width: 1390px) {
  .menu {
    width: 35%;
    &__item {
      font-size: 14px;
      padding: 20px 15px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .menu {
    width: 35%;
    //margin: 0 0 0 160px;
    &__item {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 1140px) {
  .menu {
    width: 30%;
    &__item {
      padding: 20px 10px;
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .menu {
    display: flex;
    flex-direction: column;
    margin: 0;
    &__item {
      width: 100%;
      font-size: 20px;
      text-align: left;
      color: #000;
      padding: 20px 20px 20px 0;
    }
  }
}
