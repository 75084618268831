.first {
  position: relative;
  width: 100%;
  background: #f6f6f6;
  z-index: 1;
  padding: 0 0 161px 0;

  &__observer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__policy {
    position: absolute;
    bottom: -60px;
    left: 0;
  }

  &__number {
    right: 0;
    bottom: -102px;
  }

  &__menu {
    width: 35%;
    flex-direction: row;
    margin: 0;
  }

  &__hamburger {
    display: none;
  }

  &__bg {
    max-width: 1000px;
    position: absolute;
    top: 0;
    right: 0;
    width: 55%;
    z-index: 1;
    pointer-events: none;
  }

  &__line {
    height: 110%;
    top: 0;
    left: -160px;
    z-index: 0;

    &::before, &::after {
      content: '';
      display: block;
      width: 1px;
      position: absolute;
      background: #DEDEDE;
      z-index: -1;
    }

    &::before {
      height: 363px;
      top: -278px;
      right: -108px;
    }

    &::after {
      height: 561px;
      top: 10px;
      right: -149px;
    }
  }

  &__year {
    position: absolute;
    top: 33%;
    left: -220px;
    font-size: 18px;
    color: #000;
  }

  &__home {
    margin: 0 0 85px 0;
  }

  &__logo-nikol {
    width: 180px;
    margin: 0 0 26px 0;
  }

  &__title {
    font-weight: 700;
    font-size: 35px;
    line-height: 43px;
    color: #000;
    margin: 0 0 13px 0;

    span {
      font-weight: 900;
      line-height: 43px;
      color: #08C4EE;
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: 18px;
    color: #282828;
    margin: 0 0 55px 0;
  }

  &__feedback {
    display: flex;
    align-items: center;
    margin: 0 0 81px 0;
  }

  &__whatsapp, &__input {
    height: 75px;
    border: 1px solid #08C4EE;
  }

  &__whatsapp {
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #414141;
    transition: .4s all;
    margin: 0 50px 0 0;

    &:hover {
      color: #08C4EE;
    }
  }

  &__footer {
    position: relative;
    width: 75%;
    display: flex;
    justify-content: space-between;
    margin: 0 0 31px 0;
  }

  &__video {
    display: flex;
    align-items: center;
    width: 184px;
    height: 68px;
    background: transparent;

    &:hover {
      .first {
        &__play {
          background: #41ddff url("./../img/first/play.svg") 26px (center / 18px) no-repeat;
        }
      }

      span {
        color: #08C4EE;
      }
    }

    span {
      font-size: 16px;
      margin: 0 0 0 15px;
    }
  }

  &__play {
    position: relative;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    background: #08C4EE url("./../img/first/play.svg") 26px (center / 18px) no-repeat;
  }

  &__contacts {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__address {
    margin: 0 63px 0 0;
  }

  &__input {
    width: 260px;
    height: 75px;
    background: #f6f6f6;
    border: 1px solid #08C4EE;
    font-weight: 500;
    font-size: 16px;
    color: #414141;
    outline: none;
    padding: 0 0 0 25px;
  }

  &__form {
    position: relative;
    margin: 0 75px 0 0;
  }
}

@media screen and (max-width: 1900px) {
  .first {
    &__line {
      height: 110%;
      left: 40%;

      &::after {
        height: 146%;
      }
    }

    &__menu {
      width: 35%;
      margin: 0 0 0 160px;
    }

    &__bg {
      width: 50%;
    }

    &__year {
      display: none;
    }
  }
}

@media screen and (max-width: 1650px) {
  .first {
    &__bg {
      width: 45%;
    }

    &__video {
      margin: 0 0 0 7%;
    }

    &__footer {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1390px) {
  .first {
    padding: 0 0 70px 0;

    &__line {
      &::after {
        height: 138%;
      }
    }

    &__home {
      display: none;
    }

    &__feedback {
      margin: 0 0 100px;
    }

    &__video {
      margin: 0 0 0 10%;
    }

    &__number {
      bottom: 20%;
    }
  }
}

@media screen and (max-width: 1280px) {
  .first {
    &__bg {
      width: 40%;
    }
  }
}

@media screen and (max-width: 1140px) {
  .first {
    &__line {
      &::after {
        height: 128%;
      }
    }

    &__bg {
      width: 40%;
    }

    &__feedback {
      margin: 0 0 70px;
    }

    &__contacts {
      margin: 50px 0 0 10%;
    }

    &__title {
      font-size: 32px;
      line-height: 40px;
    }

    &__footer {
      flex-direction: column;
      margin: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .first {
    &__feedback {
      height: 180px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
    }
    &__line {
      &::after {
        height: 124%;
      }
    }

    &__menu {
      display: none;
    }

    &__home {
      display: none;
    }

    &__hamburger {
      position: fixed;
      top: 50px;
      right: 3%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 44px;
      border-radius: 6px;
      background: #08C4EE;
      cursor: pointer;
      z-index: 2;

      span {
        display: block;
        width: 60%;
        height: 4px;
        background: white;
        border-radius: 20px;
        transition: .8s all;
        margin: 0 0 5px 0;

        &:last-of-type {
          margin: 0;
        }
      }

      &_active {
        span:nth-child(1) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }

        span:nth-child(2) {
          display: none;
        }

        span:nth-child(3) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    &__bg {
      width: 54%;
      top: 100px;

      &_fixed {
        position: fixed;
        transition: .9s all;
      }
    }

    &__year {
      top: 30px;
      left: 3%;
    }

    &__content {
      padding: 100px 0 0 0;
    }

    &__logo-nikol {
      margin: 0 0 15px 0;
    }

    &__title {
      font-size: 24px;
      line-height: 28px;
    }

    &__subtitle {
      font-size: 14px;
    }

    &__whatsapp {
      width: 120px;
      height: 48px;
      font-size: 14px;
    }

    &__input {
      width: 250px;
      height: 56px;

      input {
        width: 100%;
        height: 100%;
        padding: 10px;
      }
    }

    &__contacts {
      flex-wrap: wrap;
    }

    &__address {
      margin: 0 20px 30px 0;
    }

    &__phone {
      align-self: flex-end;
    }
  }
}

@media screen and (max-width: 800px) {
  .first {
    &__feedback {
      height: 120px;
      align-items: flex-start;
      margin: 0 0 90px;
    }
    &__line {
      &::after {
        height: 120%;
      }
    }

    &__footer {
      align-items: center;
    }
    &__phone {
      display: none;
    }
    &__subtitle {
      margin: 0 0 30px;
    }
  }
}

@media screen and (max-width: 576px) {
  .first {
    &__form {
      margin: 0;
    }
    &__whatsapp {
      margin: 0 20px 0 0;
    }
  }
}

@media screen and (max-width: 414px) {
  .first {
    &__title {
      font-size: 20px;
      line-height: 24px;
    }

    &__logo-nikol {
      width: 129px;
    }

    &__subtitle {
      margin: 0 0 35px;
    }

    &__feedback {
      flex-wrap: wrap;
    }

    &__whatsapp {
      margin: 0 20px 15px 0;
    }
  }
}
