body {
    &._noscroll {
        overflow: hidden;
    }

    &._hide-cart {
        .open-cart {
            display: none;
        }
    }
}

.cart {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;

    transform: translate(150%, 0);
    opacity: 0;
    transition: all 0.3s ease 0s;

    &._hide {
        pointer-events: none;
    }

    &._open {
        transform: translate(0, 0);
        opacity: 1;
    }

    // .cart__overlay

    &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;

        background: rgba(0, 0, 0, 0.5);
        z-index: 0;
        cursor: pointer;
    }

    // .cart__body

    &__body {
        width: 360px;
        height: 100%;
        background-color: #fff;
        z-index: 50;

        &._hide {
            position: relative;

            &:before {
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #00000050;
                z-index: 5;
            }
        }

        @media (max-width: 360px) {
            width: 100%;
        }
    }

    // .cart__content

    &__content {
        height: 100%;
        padding: 25px;
        overflow-y: auto;
    }

    // .cart__top

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid #08c4ee;

        h2 {
            font-size: 36px;
            line-height: 1.25;
            letter-spacing: 0.06em;
            font-weight: 900;
            font-stretch: normal;
            text-transform: uppercase;
            color: #08c4ee;
            margin: 0;

            @media (max-width: 1024px) {
                font-size: 24px;
            }

            @media screen and (max-width: 576px) {
                font-size: 20px;
            }

            @media screen and (max-width: 414px) {
                font-size: 18px;
            }
        }

        button {
            font-size: 66px;
            line-height: 0.5;
            color: #08c4ee;
            transition: 0.4s;
            background: none;
            position: relative;
            top: -6px;
            transition: all 0.3s ease 0s;

            @media (any-hover: hover) {
                &:hover {
                    color: red;
                }
            }

            @media (max-width: 1024px) {
                font-size: 44px;
            }

            @media screen and (max-width: 576px) {
                font-size: 38px;
            }

            @media screen and (max-width: 414px) {
                font-size: 32px;
            }
        }
    }

    // .cart__main

    &__main {
        & > :not(:last-child) {
            margin-bottom: 25px;
        }
    }

    // .cart__item

    &__item {
        display: flex;
        position: relative;

        img {
            width: 75px;
            height: 75px;
            object-fit: cover;
        }
        // .cart__item-content

        &-content {
            padding-left: 10px;

            &-top {
                display: flex;
                justify-content: space-between;
            }
        }

        // .cart__item-name

        &-name {
            font-size: 16px;
            line-height: 1.25;
            font-stretch: normal;
            text-transform: uppercase;
            margin-bottom: 10px;

            @media screen and (max-width: 576px) {
                font-size: 14px;
            }
        }

        // .cart__item-price

        &-price {
            font-size: 16px;
            line-height: 1.25;
            font-stretch: normal;
            @media screen and (max-width: 576px) {
                font-size: 14px;
            }
        }

        // .cart__item-remove

        &-remove {
            margin-left: 10px;
            height: 20px;
            line-height: 0.5;
            font-size: 30px;
            transition: 0.4s;
            background: none;

            transition: all 0.3s ease 0s;

            @media (any-hover: hover) {
                &:hover {
                    color: red;
                }
            }
        }

        // .cart__item-bottom

        &-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        // .cart__item-quantity

        &-quantity {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            input {
                width: 35px;
                background-color: #fff;
                margin: 0 5px;
            }

            button {
                flex: 0 0 25px;
                width: 25px;
                background-color: #08c4ee;
                color: #fff;
                padding: 0;
                transition: all 0.3s ease 0s;

                @media (any-hover: hover) {
                    &:hover {
                        background-color: #fff;
                        color: #08c4ee;
                    }
                }
            }

            input,
            button {
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                vertical-align: middle;
                text-align: center;
                font-size: 16px;
                line-height: 1.5;
                border-radius: 2px;
                border: 1px solid #08c4ee;
            }
        }
    }

    &__bottom {
        margin-top: 50px;
    }

    &__empty {
        background: unset;
        font-weight: 600;
        font-size: 18px;
        cursor: default;
    }

    &__send {
        width: 100%;
        background: #08c4ee;
        color: #fff;
        border: 2px solid #08c4ee;
        border-radius: 12px;
        transition: all 0.3s ease 0s;
        display: block;
        padding: 20px 0;
        text-align: center;
        font-weight: 600;
        font-size: 18px;

        @media screen and (max-width: 576px) {
            font-size: 16px;
            padding: 15px 0;
        }

        @media (any-hover: hover) {
            &:hover {
                background: #fff;
                color: #08c4ee;
            }
        }
    }
}

.open-cart {
    position: fixed;
    align-items: center;
    justify-content: space-between;
    right: 0;
    top: 150px;
    display: flex;
    padding: 15px 20px 10px 20px;
    background-color: #08c4ee;
    border-radius: 13px 0 0 13px;

    @media (max-width: 1024px) {
        padding: 15px 15px 10px 15px;
        right: unset;
        left: 0;
        top: unset;
        bottom: 0;
        border-radius: 0 13px 0 0;
    }

    * {
        pointer-events: none;
    }

    &__icon {
        position: relative;
    }

    &__count {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(50%, -50%);

        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        background-color: #004089;

        input {
            text-align: center;
            vertical-align: middle;
            font-size: 14px;
            width: 20px;
            height: 20px;
            background-color: #004089;
            color: #fff;
            position: relative;
            top: -1px;
        }
    }

    span {
        font-size: 20px;
        line-height: 1.25;
        font-weight: 600;
        font-stretch: normal;
        text-transform: uppercase;
        color: #fff;
        margin-right: 15px;

        @media (max-width: 1024px) {
            display: none;
        }
    }

    svg {
        width: 25px;
        fill: #fff;
    }
}
