.footer {
    min-height: 267px;
    padding: 65px 0 25px 0;
    &__body {
        display: flex;
        justify-content: space-between;
    }
    &__logo-wrap,
    &__social-wrap,
    &__copyright {
        width: calc(1390px / 3);
        position: relative;
    }
    &__logo {
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 40px;
    }
    a {
        display: block;
        padding: 3px 3px 3px 0;
    }
    &__company {
        font-size: 16px;
        line-height: 19px;
        color: #000;
        text-transform: uppercase;
        margin-bottom: 59px;
    }
    &__confidentiality {
        font-size: 16px;
        line-height: 150%;
        color: #434343;
    }

    &__social {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &__item {
            margin: 8px;
        }
    }
    &__copyright {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
    }
    &__number {
        opacity: 0;
        font-size: 16px;
        line-height: 150%;
        color: #434343;
        text-transform: uppercase;
        margin-bottom: 118px;
    }
    &__copy {
        font-size: 16px;
        line-height: 150%;
        color: #434343;
    }
}

@media screen and (max-width: 992px) {
    .footer {
        &__confidentiality {
            font-size: 14px;
            line-height: 14px;
        }
        &__copy {
            font-size: 14px;
            line-height: 14px;
        }
    }
}

@media screen and (max-width: 576px) {
    .footer {
        &__body {
            flex-direction: column;
            padding: 0 15px;
        }
        &__logo-wrap,
        &__social-wrap,
        &__copyright {
            width: 100%;
        }
        &__logo-wrap {
            text-align: center;
            margin-bottom: 50px;
        }
        &__logo {
            font-size: 25px;
            line-height: 30px;
            letter-spacing: 0.415em;
            margin-bottom: 50px;
        }
        &__company {
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            margin-bottom: 50px;
        }
        &__social {
            &__item {
                margin: 9px;
            }
        }
        &__copyright {
            display: block;
            margin-top: 28px;
        }
        &__number {
            display: none;
        }
        &__copy {
            font-size: 16px;
            line-height: 16px;
        }
    }
}
