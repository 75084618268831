.input {
  width: 100%;
  font-size: 16px;
  padding: 15px 20px;
  border-radius: 10px;

  &::placeholder {
    color: lightgray;
  }
}
