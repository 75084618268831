.gallery {
    position: relative;
    display: flex;
    justify-content: flex-end;
    //background-image: url("./../img/gallery/kirpich.jpg");
    //background-repeat: no-repeat;
    //background-size: cover;
    padding: 75px 0 56px;

    @media screen and (max-width: 1390px) {
        .container {
            padding: 0 0 0 3%;
        }
    }

    @media screen and (max-width: 830px) {
        .container {
            padding: 0;
        }

        .gallery__left {
            padding: 0 0 0 3%;
        }
    }

    &__observer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__overlay {
        display: none;
        
        &_active {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.62);
            cursor: pointer;
            z-index: 2;
        }
    }

    &__body {
        display: flex;
        justify-content: space-between;
    }

    &__left {
        width: 40%;
    }

    &__right {
        position: relative;
        width: 60%;
        padding: 102px 0 83px;

        @media screen and (max-width: 1390px) {
            padding: 142px 0 40px;
        }

        // scrollbar
        &::-webkit-scrollbar {
            position: absolute;
            top: 0;
            left: 0;
            height: 5px;
            width: 5px;
        }
        // фон
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
            background: #dfdfdf;
            border-radius: 9px;
        }
        // ползунок
        &::-webkit-scrollbar-thumb {
            border-radius: 9px;
            background: #08c4ee;
        }
    }

    &__title {
        background: rgba(0, 0, 0, 0);
        border: 1px solid #fff;
    }

    &__text {
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
        color: #d2d0d0;
        margin-bottom: 24px;
    }

    &__clients {
        position: relative;
        width: 215px;
        height: 84px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: rgba(255, 255, 255, 0.27);
        backdrop-filter: blur(71px);
        border-radius: 160px;
    }

    &__count {
        position: absolute;
        top: 50%;
        right: 13px;
        transform: translateY(-50%);
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #d7d7d7;
    }

    &__buildings {
        display: flex;
        justify-content: flex-end;

        @media (max-width: 830px) {
            display: block;
        }
    }

    &__house {
        position: relative;
        min-width: 300px;
        height: 460px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        filter: drop-shadow(20px 30px 60px rgba(14, 14, 15, 0.3));
        border-radius: 12px;
        padding: 18px 41px 29px 29px;
        margin-left: 50px;
        transition: 0.4s all;
        cursor: pointer;

        @media screen and (max-width: 1390px) {
            min-width: 250px;
            height: 400px;
            margin-left: 30px;
        }

        &_active {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1.5);
            z-index: 4;
        }

        @media (max-width: 830px) {
            margin-left: 0;
            margin-bottom: 30px;

            height: 350px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        @media (max-width:768px){
            height: 300px;
        }

        @media (max-width:600px){
            height: 200px;
        }   

        &:nth-of-type(1) {
            margin-left: 0;
        }
    }

    &__object {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__descr {
        position: relative;
        font-weight: 900;
        font-size: 30px;
        line-height: 30px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #fff;

        @media screen and (max-width: 1390px) {
            font-size: 28px;
            line-height: 28px;
        }

        span {
            font-weight: 600;
            font-size: 20px;
            letter-spacing: 0;
            color: #dfdfdf;

            @media screen and (max-width: 1390px) {
                font-size: 18px;
                line-height: 18px;
            }
        }
    }

    &__update {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &__number {
        top: 100%;
        left: 0;
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        @media (max-width: 830px) {
            height: 400px;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(69, 109, 68, 0.16);
        }
    }

    &__bg-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }

    &__item {
        position: absolute;
        top: 50%;
        left: 13px;
        width: 60px;
        height: 60px;
        transform: translateY(-50%);
        z-index: 3;

        &:nth-of-type(2) {
            transform: translate(50%, -50%);
            z-index: 2;
        }

        &:nth-of-type(3) {
            transform: translate(100%, -50%);
            z-index: 1;
        }

        img {
            width: 100%;
            border-radius: 50%;
            border: 1px solid #fff;
        }
    }
}

@media screen and (max-width: 1024px) {
    .gallery {
        &__body {
            flex-direction: column;
        }

        &__title {
            margin: 0;
        }

        &__right {
            width: 100%;
            padding: 40px 0 40px 0;
        }

        &__update {
            position: relative;
        }
    }
}

@media screen and (max-width: 830px) {
    .gallery {
        &__right {
            padding: 40px 0 0 0;
        }
    }
}

@media screen and (max-width: 800px) {
    .gallery {
        &__house {
            &_active {
                transform: translate(-50%, -50%) scale(1.2);
            }
        }
    }
}
