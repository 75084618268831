.tabs {
  &__nav {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0 0 82px 0;
  }
 &__btn {
   background: transparent;
   text-decoration: none;
   color: black;
   text-align: center;
   border: 1px solid transparent;
   cursor: pointer;
   margin: 0 100px 0 0;
   &:last-of-type {
     margin: 0;
   }
   h3 {
     font-weight: 800;
     font-size: 40px;
     line-height: 48px;
     cursor: pointer;
     text-transform: uppercase;
     color: #C4C4C4;
     z-index: 1;
   }
 }
}

.tabs__btn_active {
  h3 {
    color: #08C4EE;
  }
}

.tabs__content {
  padding: 1rem;
}

.tabs__pane {
  display: none;
}

.tabs__pane_show {
  display: block;
}

@media screen and (max-width: 1024px) {
  .tabs {
    &__nav {
      margin: 0 0 50px 0;
    }
    &__btn {
      margin: 0 70px 0 0;
      h3 {
        font-size: 30px;
        z-index: 0;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .tabs {
    &__nav {
      margin: 0 0 50px 0;
    }
    &__btn {
      margin: 0 50px 0 0;
      h3 {
        font-size: 26px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .tabs {
    &__nav {
      margin: 0 0 40px 0;
    }
    &__btn {
      margin: 0 40px 0 0;
      h3 {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .tabs {
    &__nav {
      margin: 0 0 20px 0;
    }
    &__btn {
      margin: 0 20px 0 0;
      h3 {
        font-size: 18px;
      }
    }
  }
}
