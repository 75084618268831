* {
  padding: 0;
  margin: 0;
  border: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  &:before {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  &:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
}
a {
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
  text-decoration: none;
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
}
nav {
  display: block;
}
footer {
  display: block;
}
header {
  display: block;
}
aside {
  display: block;
}
html {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
input {
  font-family: inherit;
  &::-ms-clear {
    display: none;
  }
}
button {
  font-family: inherit;
  cursor: pointer;
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}
textarea {
  font-family: inherit;
}
ul {
  li {
    list-style: none;
  }
}
img {
  vertical-align: top;
}
h1 {
  font-size: inherit;
  font-weight: 400;
  color: rgb(240, 240, 240);
}
h2 {
  font-size: inherit;
  font-weight: 400;
  color: rgb(240, 240, 240);
}
h3 {
  font-size: inherit;
  font-weight: 400;
}
h4 {
  font-size: inherit;
  font-weight: 400;
}
h5 {
  font-size: inherit;
  font-weight: 400;
}
h6 {
  font-size: inherit;
  font-weight: 400;
}