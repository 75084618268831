.video {
    position: relative;
    background: #f6f6f6;
    padding: 20px 0 149px 0;
    z-index: 0;

    &__observer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 50px;
    }

    &__title {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 5;
        background: #fff;
        box-shadow: 20px 20px 60px rgba(14, 14, 15, 0.1);

        h2 {
            color: #08c4ee;
        }
    }

    &__block {
        position: relative;
        width: 100%;
        height: 0;
        padding: 0 0 56.25% 0;

        &:hover {
            .video__button {
                background: red;
            }
        }

        &_one {
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 3;
        }

        &_two {
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 3;
            grid-column-end: 5;
        }
    }

    iframe {
        display: none;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }

    &__button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 68px;
        height: 68px;
        background: #08c4ee;
        border-radius: 50%;
        z-index: 10;

        &:focus {
            .video__button {
                background: red;
            }
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 36%;
            left: 40%;
            border-width: 10px 0 10px 20px;
            border-color: transparent transparent transparent #ffffff;
            border-style: solid;
        }
    }

    &__number {
        top: 175px;
        left: 961px;
    }

    &__block-white {
        position: absolute;
        width: 153px;
        height: 266px;
        top: 112px;
        left: 230px;
        background: #ffffff;
        box-shadow: 20px 20px 60px rgba(14, 14, 15, 6%);
        z-index: -1;

        &::before {
            content: "";
            position: absolute;
            width: 153px;
            height: 266px;
            top: 569px;
            left: 208px;
            background: #ffffff;
            box-shadow: 20px 20px 60px rgba(14, 14, 15, 6%);
            z-index: -1;
        }

        &::after {
            content: "";
            position: absolute;
            width: 265px;
            height: 45px;
            top: 1570px;
            left: -180px;
            background: #ffffff;
            box-shadow: 20px 20px 60px rgba(14, 14, 15, 8%);
            z-index: -1;
        }
    }

    &__line {
        height: 1062px;
        width: 1px;
        background: #dedede;
        top: 568px;
        left: 370px;
        position: absolute;
        z-index: -1;

        &::before {
            content: "";
            height: 918px;
            width: 1px;
            background: #dedede;
            top: 146px;
            left: 352px;
            position: absolute;
        }
    }
}

@media screen and (max-width: 1440px) {
    .video {
        &__content {
            grid-template-columns: 1fr 1fr 1fr;
            row-gap: 70px;
            padding: 100px 0 0 0;
        }

        &__title {
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 2;
        }

        &__block {
            &_one {
                grid-row-start: 1;
                grid-row-end: 2;
                grid-column-start: 2;
                grid-column-end: 4;
                justify-self: flex-end;
            }

            &_two {
                grid-row-start: 2;
                grid-row-end: 3;
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }

        &__block-white {
            top: 0;
            left: 50%;
        }

        &__line {
            top: 0;
        }

        &__number {
            top: 75%;
            left: auto;
            right: 0;
        }
    }
}

@media screen and (max-width: 1024px) {
    .video {
        padding: 20px 0 100px 0;
    }
}

@media screen and (max-width: 800px) {
    .video {
        padding: 0;

        &__content {
            grid-template-columns: 1fr;
            grid-template-rows: 155px 1fr 1fr;
            gap: 35px;
            padding: 100px 0 0 0;
        }

        &__title {
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 2;

            h2 {
                font-size: 16px;
                line-height: 19px;
            }
        }

        &__block {
            &_one {
                grid-row-start: 2;
                grid-row-end: 3;
                grid-column-start: 1;
                grid-column-end: 2;
                justify-self: flex-end;
            }

            &_two {
                grid-row-start: 3;
                grid-row-end: 4;
                grid-column-start: 1;
                grid-column-end: 2;
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .video {
        &__content {
            padding: 0;
        }
    }
}

@media screen and (max-width: 510px) {
    .video {
        &__number {
            top: auto;
            bottom: -110px;
            left: 0;
            right: 0;
        }
    }
}
