.plus {
  position: relative;
  background: #f6f6f6;
  padding: 103px 0 51px 0;

  &__observer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__header {
    display: grid;
    grid-template-columns: 310px 310px 670px;
    grid-template-rows: minmax(333px, 1fr);
    column-gap: 50px;
    margin-bottom: 45px;
    z-index: 0;
  }

  &__body {
    display: grid;
    grid-template-columns: minmax(1fr, 670px) minmax(1fr, 670px);
    grid-template-rows: minmax(332px, 1fr) minmax(332px, 1fr);
    column-gap: 50px;
    row-gap: 45px;
    margin-bottom: 45px;
  }

  &__image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }

    &_xl {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  &__description {
    width: 100%;
  }

  &__subtitle {
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 16px;
    line-height: 19px;
    text-align: justify;
    color: #767676;
    margin-bottom: 36px;
  }

  &__button {
    border: 2px solid #08C4EE;
    background: #fff;
    border-radius: 50px;
    font-weight: 600;
    font-size: 16px;
    color: #08C4EE;
    transition: .4s all;
    padding: 25px 30px;

    &:hover {
      background: #08C4EE;
      color: white;
    }
  }

  &__number {
    height: 153px;
    top: 569px;
    left: 1390px;
  }
}

@media screen and (max-width: 1440px) {
  .plus {
    &__header {
      grid-template-columns: 228px minmax(190px, 1fr) minmax(457px, 1fr);
      grid-template-rows: minmax(1fr, 367px);
      column-gap: 33px;
      margin-bottom: 65px;
    }
  }
}

@media screen and (max-width: 1390px) {
  .plus {
    &__body {
      grid-template-columns: minmax(1fr, 492px) minmax(1fr, 492px);
      grid-template-rows: minmax(224px, 1fr) minmax(224px, 1fr);
    }
  }
}

@media screen and (max-width: 1140px) {
  .plus {
    &__body {
      grid-template-columns: minmax(1fr, 492px) minmax(1fr, 492px);
      grid-template-rows: minmax(224px, 1fr) minmax(224px, 1fr);
      gap: 33px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .plus {
    &__header {
      grid-template-columns: 228px minmax(180px, 1fr);
      grid-template-rows: minmax(155px, 289px) 1fr;
      column-gap: 26px;
      row-gap: 73px;
      margin-bottom: 32px;
    }

    &__description {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

@media screen and (max-width: 800px) {
  .plus {
    &__header {
      grid-template-columns: 150px minmax(120px, 1fr);
      grid-template-rows: minmax(155px, 260px) 1fr;
      column-gap: 20px;
      row-gap: 50px;
    }

    &__body {
      grid-template-columns: 1fr;
      grid-template-rows: minmax(224px, 1fr) minmax(224px, 1fr) minmax(224px, 1fr);
      gap: 33px;
    }

    &__title {
      width: 150px;

      h2 {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &__image {
      &_xl {
        grid-column-start: auto;
        grid-column-end: auto;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .plus {
    &__header {
      column-gap: 15px;
      row-gap: 30px;
    }

    &__title {
      width: 145px;
      height: 115px;
    }
  }
}
