.social {
  position: absolute;
  top: 0;
  left: -200px;
  width: 18px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  &__item {
    display: block;
    width: 18px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    &_vk {
      background-image: url("./../img/first/vk.svg");
    }
    &_insta {
      background-image: url("./../img/first/insta.svg");
    }
    &_youtube {
      width: 18px;
      background-image: url("./../img/first/youtube.png");

    }
  }

}

@media screen and (max-width: 1900px) {
  .social {
    left: -100px;
  }
}

@media screen and (max-width: 1650px) {
  .social {
    left: 0;
  }
}
