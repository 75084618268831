.title {
  width: 310px;
  height: 330px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: #08C4EE;
  box-shadow: 20px 20px 60px rgba(14, 14, 15, 10%);
  border-radius: 12px;
  padding: 0 0 50px 26px;
  h2 {
    font-weight: 900;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #FFF;
  }
}

@media screen and (max-width: 1440px) {
  .title {
    width: 228px;
    height: 245px;
    padding: 0 0 40px 19px;
    h2 {
      //font-size: 26px;
      //line-height: 31px;
      font-size: 20px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .title {
    padding: 0 0 19px 14px;
  }
}

@media screen and (max-width: 800px) {
  .title {
    width: 150px;
    height: 155px;
  }
}

@media screen and (max-width: 576px) {
  .title {
    padding: 0 0 19px 14px;
    h2 {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

@media screen and (max-width: 414px) {
  .title {
    width: 125px;
    height: 115px;
  }
}
