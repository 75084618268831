.goods {
    position: relative;
    display: none;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    &_show {
        display: flex;
    }

    &__card {
        position: relative;
        &:hover {
            .goods__img {
                img {
                    filter: grayscale(0);
                    opacity: 1;
                    transform: scale(1.1);
                }
            }
        }
    }

    &__new {
        position: absolute;
        top: 14px;
        left: 16px;
        font-weight: 300;
        font-size: 12px;
        color: #08c4ee;
        text-transform: uppercase;
    }

    &__img {
        width: 308px;
        height: 172px;
        overflow: hidden;
        margin-bottom: 21px;

        img {
            width: 100%;
            height: 100%;
            filter: grayscale(100%);
            opacity: 0.3;
            transition: 0.8s all;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 18px;
        color: #08c4ee;
        line-height: 20px;
        margin: 0 0 52px 0;

        span {
            color: #666;
            font-size: 14px;
        }

        &_mb20 {
            margin: 0 0 20px 0;
        }

        &:first-letter {
            text-transform: uppercase;
        }
    }

    &__size {
        font-weight: 300;
        font-size: 12px;
        color: #000;
        margin: 0 0 30px 0;
    }

    &__description {
        position: relative;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin: 0 0 34px 35px;
    }

    &__icon {
        width: 20px;
        position: absolute;
        top: -3px;
        left: -28px;
    }

    &__price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 29px;

        &__thing {
            font-size: 16px;
            color: #000000;
        }

        span {
            font-size: 14px;
            text-decoration-line: line-through;
            color: #c0c0c0;
        }

        &__package {
            font-size: 16px;
            color: #000000;
        }
    }

    &__discount {
        position: absolute;
        left: 90px;
        top: -12px;
        background: #e7f5ed;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.11);
        border-radius: 4px;
        font-size: 13px;
        color: #27ae60;
        padding: 8px;
    }

    &__button {
        display: block;
        width: 250px;
        height: 54px;
        padding: 13px 36px;
        border: 1px solid #08c4ee;
        border-radius: 40px;
        font-weight: 500;
        font-size: 16px;
        background: #fff;
        text-align: center;
        color: #08c4ee;
        transition: 0.4s all;
        margin: 0 auto;

        &:first-letter {
            text-transform: uppercase;
        }

        &:hover {
            background: #08c4ee;
            color: white;
        }

        &:disabled {
            background-color: #08c4ee;
            color: white;
        }
    }
}

@media screen and (max-width: 1024px) {
    .goods {
        &__img {
            img {
                filter: none;
                opacity: 1;
                transition: none;
            }
        }

        &__card {
            &:hover {
                .goods__img {
                    img {
                        transform: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 414px) {
    .goods {
        &__img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
}
