.catalog {
  position: relative;
  background: #f6f6f6;
  padding: 59px 0 154px 0;
  z-index: -2;

  &__observer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__number {
    top: 1107px;
    left: -265px;
  }

  &__line {
    position: absolute;
    height: 55%;
    top: 1445px;
    left: -190px;
    background: #DEDEDE;
    z-index: 0;

    &::before {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      top: -245px;
      left: 217px;
      background: #DEDEDE;
    }

    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      top: -582px;
      left: 500px;
      background: #DEDEDE;
    }
  }

  &__line-2 {
    position: absolute;
    height: 55%;
    top: 537px;
    left: 567px;
    background: #DEDEDE;
    z-index: 0;

    &::before {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      top: -302px;
      left: 168px;
      background: #DEDEDE;
    }

    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      top: 620px;
      left: 703px;
      background: #DEDEDE;
    }
  }

  &__header {
    width: 100%;
    display: grid;
    grid-template-rows: 330px;
    grid-template-columns: 310px 1fr;
    margin-bottom: 114px;
  }

  &__title {
    width: 100%;
    height: 100%;
    padding: 0 0 49px 26px;
  }

  &__logo {
    font-size: 129px;
    letter-spacing: 0.415em;
  }
}

@media screen and (max-width: 1440px) {
  .catalog {
    padding: 59px 0 100px;

    &__header {
      grid-template-rows: 245px;
      grid-template-columns: 228px 1fr;
      margin-bottom: 114px;
    }

    &__logo {
      font-size: 100px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .catalog {
    &__header {
      gap: 50px;
    }

    &__logo {
      font-size: 90px;
    }
  }
}

@media screen and (max-width: 1140px) {
  .catalog {
    &__logo {
      font-size: 80px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .catalog {
    &__header {
      gap: 30px;
      margin-bottom: 80px;
    }

    &__logo {
      font-size: 75px;
    }
  }
}

@media screen and (max-width: 900px) {
  .catalog {
    &__header {
      margin-bottom: 80px;
    }

    &__logo {
      font-size: 66px;
    }
  }
}

@media screen and (max-width: 800px) {
  .catalog {
    &__header {
      grid-template-rows: 155px;
      grid-template-columns: 135px 1fr;
    }

    &__logo {
      font-size: 60px;
    }
  }
}

@media screen and (max-width: 678px) {
  .catalog {
    &__logo {
      font-size: 50px;
    }
  }
}

@media screen and (max-width: 576px) {
  .catalog {
    &__header {
      margin-bottom: 50px;
    }

    &__logo {
      font-size: 40px;
    }
  }
}

@media screen and (max-width: 500px) {
  .catalog {
    &__header {
      margin-bottom: 30px;
    }

    &__logo {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: 414px) {
  .catalog {
    padding: 59px 0 150px;

    &__header {
      grid-template-rows: 125px;
      grid-template-columns: 115px 1fr;
      gap: 10px;
      margin-bottom: 30px;
    }

    &__logo {
      font-size: 20px;
    }
  }
}
