.pagination {
  width: 100%;
  display: flex;
  justify-content: end;
  font-size: 18px;

  &__item {
    padding: 10px;
    margin: 0 23px 0 0;
    cursor: pointer;
    a {
      color: #949494;
    }
    &_active {
      a {
        color: #000;
      }
    }
    &:last-of-type {
      margin: 0;
    }
  }
  &__item-components {
    padding: 10px;
    margin: 0 23px 0 0;
    cursor: pointer;
    a {
      color: #949494;
    }
    &_active {
      a {
        color: #000;
      }
    }
    &:last-of-type {
      margin: 0;
    }
  }
}

@media screen and (max-width: 414px) {
  .pagination {
    &__item {
      font-size: 12px;
    }
  }
}
