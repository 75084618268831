.menu-mobile {
  width: 100%;
  height: 100vh;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  transition: .8s all;
  z-index: 1;
  padding: 30px 20px 20px;
  &_hidden {
    left: -100%;
  }
  &__logo {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    font-size: 25px;
    letter-spacing: 0.415em;
    z-index: 2;
    margin: 0 0 10px 0;
  }
  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 36px 0 40px 0;
  }
  &__phone {
    position: absolute;
    right: 50%;
    bottom: 0;
    padding: 20px 0 10px 0;
  }
  &__social {
    position: relative;
    margin: 0 auto 10px;
  }
  &__address {
    margin: 0;
  }
}
@media screen and (max-width: 768px) {
  .menu-mobile {
    &__phone {
      right: 0;
    }
  }
}
