.btn {
  background: #08C4EE;
  border-radius: 42px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #FFF;
  transition: .4s background-color;
  padding: 15px 30px;

  &:hover {
    background: white;
    color: #08C4EE;
    outline: 1px solid #08C4EE;
  }

  &__send {
    position: relative;
    display: block;
    height: 2px;
    width: 20px;
    background: black;
    margin: 0 auto;
    &::before, &::after {
      content: '';
      display: block;
      height: 2px;
      width: 10px;
      position: absolute;
      border-radius: 10px;
      background: black;
    }
    &::before {
      top: -3px;
      right: -2px;
      transform: rotate(45deg);
    }
    &::after {
      top: 3px;
      right: -2px;
      transform: rotate(-45deg);
    }
  }
  &:first-letter {
    text-transform: uppercase;
  }

  &_center {
    display: block;
    margin: 0 auto;
  }
}
