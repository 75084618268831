* {
  //border: 1px solid red;
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-decoration: none;

  // отмена выделения текста
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  //  убрать стрелки input
  // Chrome
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Firefox
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="number"]:hover,

  // other
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body {
  position: relative;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  z-index: 0;
}

input, input:before, input:after {
  color: #414141;
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

h2 {
  font-size: 20px;
  color: #3c3c3c;
  font-weight: bold;
  margin: 10px 0;
}

h3 {
  font-size: 18px;
  color: #3c3c3c;
  font-weight: bold;
  margin: 10px 0;
}
