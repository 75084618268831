.preloader {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;

  &_visible {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: visible;
    z-index: 5;
    visibility: visible;
    opacity: 1;
    transition: opacity 2s linear;
  }

  &__block {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
  }

  &__gif {
    width: 100%;
  }

  &__title {
    font-size: 30px;
    color: #08C4EE;
    text-align: center;
  }

  &__point-1 {
    animation: points-preloader 1s ease infinite;
  }
  &__point-2 {
    animation: points-preloader 1.1s ease infinite;
  }
  &__point-3 {
    animation: points-preloader 1.2s ease infinite;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
  }
}
