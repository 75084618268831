.certificate {
    position: relative;
    background: #f6f6f6;
    padding: 116px 0 165px;
    z-index: 0;

    &__observer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__body {
        display: flex;
        justify-content: space-between;
    }

    &__left {
        width: 50%;
        position: relative;
        padding: 97px 0 0 0;
    }

    &__img {
        display: block;
        width: 316px;
        height: 240px;
    }

    &__ipad {
        position: relative;

        &::before {
            content: "";
            display: block;
            width: 316px;
            height: 240px;
            position: absolute;
            top: 15%;
            right: -20px;
            background-image: url("./../img/certificate/bg.png");
            background-size: cover;
        }

        img {
            position: relative;
            width: 616px;
            height: 707px;
            object-fit: cover;
        }
    }

    &__right {
        width: 50%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &__title {
        width: 360px;
        background: #fff;
        margin-bottom: 174px;

        h2 {
            color: #08c4ee;
        }
    }

    &__document {
        width: 461px;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60%;
        right: 0;
    }

    &__doc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 425px;
        height: 90px;
        background: #fff;
        box-shadow: 20px 20px 60px rgba(14, 14, 15, 7%);
        border-radius: 12px;
        padding: 5px 5px 5px 55px;
        margin: 0 0 35px 0;

        &:first-of-type {
            align-self: flex-end;
        }

        &:last-of-type {
            margin: 0;
        }
    }

    &__text {
        font-size: 24px;
        color: #32353c;
        line-height: 3;

        &::before {
            content: url("../img/certificate/cert-ico.svg");
            position: relative;
            top: 5px;
            left: -24px;
        }
    }

    &__button {
        width: 111px;
        height: 78px;
        background: #08c4ee;
        border-radius: 13px;
        font-size: 16px;
        text-align: center;
        color: #fff;
    }

    &__line {
        position: absolute;
        height: 152%;
        top: 70px;
        left: 303px;
        background: #dedede;
        z-index: -1;

        &::before {
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            top: 0px;
            left: 594px;
            background: #dedede;
        }

        &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            top: 215px;
            left: 197px;
            background: #dedede;
        }
    }

    &__line-2 {
        position: absolute;
        width: 1px;
        height: 130%;
        top: 515px;
        right: -214px;
        background: #dedede;
    }
}

@media screen and (max-width: 1440px) {
    .certificate {
        &__title {
            width: 220px;
        }
    }
}

@media screen and (max-width: 1140px) {
    .certificate {
        padding: 200px 0 300px;
        &__left {
            padding: 0;
        }

        &__ipad {
            &::before {
                right: auto;
                left: 75%;
            }
            img {
                width: 433px;
                height: 497px;
            }
        }

        &__document {
            top: auto;
            right: 100px;
            bottom: -200px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .certificate {
        &__title {
            align-self: baseline;
        }
        &__body {
            flex-direction: column-reverse;
        }

        &__left {
            width: 100%;
        }

        &__ipad {
            &::before {
                right: auto;
                left: 50%;
                top: -200px;
            }
            img {
                width: 433px;
                height: 497px;
            }
        }

        &__right {
            width: 100%;
        }

        &__document {
            bottom: -250px;
        }
    }
}

@media screen and (max-width: 800px) {
    .certificate {
        padding: 71px 0 300px;

        &__title {
            width: 170px;
            align-self: baseline;
            h2 {
                font-size: 16px;
                line-height: 19px;
            }
        }

        &__ipad {
            img {
                display: block;
                width: 197px;
                height: 226px;
                margin: 0 auto;
            }
            &::before {
                width: 138px;
                height: 104px;
                top: -100px;
                right: 33px;
            }
        }

        &__document {
            right: 50%;
            transform: translateX(50%);
            bottom: -250px;
        }

        &__title {
            margin-bottom: 50px;
        }

        &__document {
            width: 300px;
            bottom: -215px;
        }

        &__doc {
            width: 270px;
            height: 67px;
            padding: 5px 5px 5px 40px;
            margin: 0 0 26px;
        }

        &__text {
            font-size: 16px;
        }

        &__button {
            width: 83px;
            height: 50px;
            font-size: 12px;
        }
    }
}
