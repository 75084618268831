.card {
  position: relative;
  width: 360px;
  background: #FFFFFF;
  box-shadow: 20px 20px 60px rgb(14 14 15 / 16%);
  border-radius: 12px;
  transition: .8s all;
  padding: 30px 26px 30px;
  margin: 0 20px 70px;

  &:hover {
    box-shadow: 20px 20px 60px rgb(14 14 15 / 46%);
    transform: translateY(-3px);
  }
}

@media screen and (max-width: 1024px) {
  .card {
    &:hover {
      box-shadow: 20px 20px 60px rgb(14 14 15 / 16%);
      transform: none;
    }
  }
}

@media screen and (max-width: 414px) {
  .card {
    width: 300px;
    margin: 0 20px 50px;
  }
}
