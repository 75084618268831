.slider {
  //width: 100%;
  position: relative;
  border-radius: 32px;
  &__line {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    transition: .4s all ease-in-out;
    z-index: 1;
  }
}
