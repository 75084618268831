.slide {
  width: 1390px;
}

@media screen and (max-width: 1280px) {
  .slide {
    &:last-of-type {
      margin: 0;
    }
  }
}
