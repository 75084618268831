.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90vh;
    display: none;
    overflow: inherit;
    padding: 50px;

    &__cards {
        width: 500px;
        max-width: 600px;


        &.popup_active {
            z-index: 100;
        }
    }

    &__error {
        &.popup_active {
            z-index: 100;
        }
    }

    &__thanks {
        display: none;

        &.popup_active {
            z-index: 100;
        }
    }

    &__title {
        font-size: 24px;
        color: #08c4ee;
        margin: 0 0 30px 0;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    &__text {
        font-size: 16px;
        line-height: 18px;
        color: black;
        margin: 0 0 30px 0;
    }

    &__img {
        outline: none;
        border: none;
        width: 100%;
        height: 100%;
        margin: 0 0 20px 0;
    }

    &__policy {
        min-width: 80%;
        overflow: auto;
    }

    &__video {
        max-width: 600px;
        padding: 0;
    }

    &__video-show {
        z-index: 2;
        &_active {
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: content-box;
            background: white;
            padding: 30px;
        }
    }

    &__status {
        display: none;

        &_active {
            display: block;
            color: red;
        }
    }

    &__feedback {
        width: 450px;
    }

    &__certificate {
        width: 600px;
        overflow: auto;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__button-close {
        font-size: 66px;
        line-height: 40px;
        position: absolute;
        top: 0;
        right: 0;
        color: #08c4ee;
        cursor: pointer;
        transition: 0.4s all;
        padding: 10px;
        z-index: 1;

        &:hover {
            color: red;
        }
    }

    &__close-button {
        display: inline-block;
        padding: 15px 30px;
        border: 1px solid #8b8b8b;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.4s all;
        margin: 0 auto;

        &:hover {
            background: #08c4ee;
            color: white;
        }
    }

    &__quadrocopter {
        width: 600px;
        overflow: auto;
    }

    &_active {
        display: block;
        background: white;
        box-shadow: 20px 20px 60px rgb(14 14 15 / 16%);
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        padding: 50px;
        z-index: 2;
    }

    &__gallery {
        max-width: 1200px;
        width: 90%;
        overflow: hidden;
        padding: 0;
    }

    &__slider {
        position: relative;
        width: 100%;
    }

    &__slider-direction {
        width: 100%;
        height: 80px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__slider-left {
        width: 80px;
        height: 80px;
        background: rgba(8, 196, 238, 0.5);
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 20px;
        cursor: pointer;
        transition: 0.4s all;

        &::before {
            content: "";
            width: 10px;
            height: 40px;
            border-radius: 5px;
            background: white;
            position: absolute;
            top: 7px;
            left: 17px;
            transform-origin: bottom right;
            transform: rotate(45deg);
            transition: 0.4s all;
        }
        &::after {
            content: "";
            width: 10px;
            height: 40px;
            border-radius: 5px;
            background: white;
            position: absolute;
            bottom: 7px;
            left: 17px;
            transform-origin: top right;
            transform: rotate(-45deg);
            transition: 0.4s all;
        }

        &:hover {
            background: white;
            &::before {
                background: #08c4ee;
            }
            &::after {
                background: #08c4ee;
            }
        }

        @media (max-width: 1024px) {
            width: 60px;
            height: 60px;

            &::before {
                height: 30px;
                left: 11px;
            }

            &::after {
                height: 30px;
                left: 11px;
            }
        }

        @media (max-width: 800px) {
            display: none;
        }
    }

    &__slider-right {
        width: 80px;
        height: 80px;
        background: rgba(8, 196, 238, 0.5);
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 20px;
        transform: rotate(180deg);
        cursor: pointer;
        transition: 0.4s all;

        &::before {
            content: "";
            width: 10px;
            height: 40px;
            border-radius: 5px;
            background: white;
            position: absolute;
            top: 7px;
            left: 17px;
            transform-origin: bottom right;
            transform: rotate(45deg);
            transition: 0.4s all;
        }
        &::after {
            content: "";
            width: 10px;
            height: 40px;
            border-radius: 5px;
            background: white;
            position: absolute;
            bottom: 7px;
            left: 17px;
            transform-origin: top right;
            transform: rotate(-45deg);
            transition: 0.4s all;
        }
        &:hover {
            background: white;
            &::before {
                background: #08c4ee;
            }
            &::after {
                background: #08c4ee;
            }
        }

        @media (max-width: 1024px) {
            width: 60px;
            height: 60px;

            &::before {
                height: 30px;
                left: 11px;
            }

            &::after {
                height: 30px;
                left: 11px;
            }
        }

        @media (max-width: 800px) {
            display: none;
        }
    }

    &__image {
        min-width: 1200px;
    }

    &__photo {
        width: 100%;
        object-fit: contain;
    }

    &__category {
        width: 100%;
        padding: 0 20px;
        margin-top: 10px;
    }

    &__category-item {
        color: white;
        background: #08c4ee;
        border: 1px solid #08c4ee;
        border-radius: 20px;
        padding: 10px 20px;
        transition: all 0.3s ease 0s;
        cursor: pointer;

        &._active {
            color: #08c4ee;
            background: #fff;
        }

        @media (any-hover: hover) {
            &:hover {
                color: #08c4ee;
                background: #fff;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .popup {
        width: 90%;
        max-width: 90%;

        &__video {
            width: 90%;
        }

        &_active {
            box-shadow: none;
            padding: 50px 25px;

            &.popup__gallery {
                padding: 20px 20px 25px 20px;
            }
        }

        &__category {
            padding: 0;
        }

        &__video-close {
            font-size: 66px;
            line-height: 40px;
            position: absolute;
            top: -50px;
            right: -10px;
            color: #08c4ee;
            cursor: pointer;
            padding: 10px;
        }
    }
}
