.main {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
  position: relative;
  overflow: hidden;
  z-index: 0;

  &_hidden {
    visibility: hidden;
    opacity: 0;
  }
}
