.phone {
  display: flex;
  flex-direction: column;
  &__item {
    text-align: right;
    font-weight: 500;
    font-size: 16px;
    color: #000;
    margin: 0 0 7px 0;
    &_blue {
      text-decoration-line: underline;
      color: #08C4EE;
    }
  }
}

@media screen and (max-width: 414px) {
  .phone {
    &__item {
      font-size: 14px;
    }
  }
}
