.header-fix {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 119px;
  background: #F6F6F6;
  box-shadow: 20px 20px 60px rgba(14, 14, 15, 0.05);
  transition: .8s all;
  z-index: 2;
  &_disabled {
    top: -120px;
  }
  &__container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1340px) {
  .header-fix {
    .address {
      display: none;
    }
  }
}

@media screen and (max-width: 1024px) {
  .header-fix {
    display: none;
  }
}
