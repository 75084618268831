.address {
  font-style: normal;

  &__title {
    font-weight: 700;
    color: #000;
    margin: 0 0 11px 0;
    &_mb {
      font-weight: 600;
      font-size: 20px;
      margin: 0;
    }
  }
  &__text {
    position: relative;
    color: #656565;
    padding: 0 0 0 28px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 20px;
      height: 24px;
      background-image: url('./../img/address.svg');
      background-repeat: no-repeat;
    }
  }
  &__text {
    &_time {
      &::before {
        content: '';
        background-image: url('./../img/clock.svg');
        height: 20px;
      }
    }
    &_phone {
      &::before {
        content: '';
        background-image: url('./../img/phone.svg');
        width: 15px;
        height: 15px;
      }
    }
    &_wp {
      &::before {
        content: '';
        background-image: url('./../img/wp.svg');
        width: 18px;
        height: 18px;
      }
    }
    &_lsm {
      &::before {
        content: '';
        background-image: url('./../img/lsm.svg');
        width: 18px;
        height: 14px;
      }
    }
  }
}
