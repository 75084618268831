.application {
  min-height: 861px;
  position: relative;
  background: #f6f6f6;
  padding: 165px 0;
  z-index: 0;

  &__observer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__photo {
    position: absolute;
    top: -50px;
    right: -65px;
    width: 558px;
    z-index: 2;

    img {
      width: 100%;
    }
  }

  &__number {
    top: 203px;
    right: -265px;
  }

  &__container {
    &::before {
      content: '';
      display: block;
      width: 266px;
      height: 165px;
      background: #08C4EE;
      position: absolute;
      top: -165px;
      left: 26%;
    }

    &::after {
      content: '';
      width: 266px;
      height: 165px;
      background: #08C4EE;
      position: absolute;
      right: 10%;
      bottom: -165px;
    }
  }

  &__line {
    height: 918px;
    width: 1px;
    background: #DEDEDE;
    top: 145px;
    left: 527px;
    position: absolute;
    z-index: -1;

    &::before {
      content: '';
      height: 918px;
      width: 1px;
      background: #DEDEDE;
      top: -197px;
      left: 194px;
      position: absolute;
    }

    &::after {
      content: '';
      height: 918px;
      width: 1px;
      background: #DEDEDE;
      top: 212px;
      left: 929px;
      position: absolute;
    }
  }

  &__wrapper {
    min-height: 531px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFF;
    box-shadow: 20px 20px 60px rgba(14, 14, 15, 0.16);
    border-radius: 32px;

    &:first-of-type {
      margin: 0;
    }

    &:last-of-type {
      margin: 0;
    }
  }

  &__froze {
    width: 50%;
    padding: 121px 0 121px 94px;
  }

  &__blank {
    width: 50%;
  }

  &__title {
    font-weight: 700;
    font-size: 22px;
    line-height: 110.2%;
    color: #232323;
    margin-bottom: 14px;
  }

  &__answer, &__quadrocopter {
    font-weight: 400;
    font-size: 18px;
    line-height: 110.2%;
    color: #767676;
    margin-bottom: 34px;

    span {
      font-size: 18px;
      line-height: 110.2%;
      color: #08C4EE;
    }
  }

  &__quadrocopter {
    background: transparent;
    margin: 0;
    padding: 5px;

    span {
      color: #08C4EE;
    }

    &:hover {
      background: #dfdfdf;
      border-radius: 20px;
    }
  }

  &__question {
    font-size: 14px;
    color: #767676;
    margin-bottom: 12px;
  }

  &__line-stage {
    position: relative;
    margin: 0 0 46px -10px;

    button {
      background: transparent;
      cursor: default;
      padding: 10px;

      span {
        display: inline-block;
        width: 47px;
        height: 2px;
        background: #767676;
        transition: .4s all;
        margin: 0 16px 0 0;
      }
    }

    .active {

      &:hover {
        cursor: pointer;

        span {
          height: 5px;
        }
      }
    }

    .blue {
      span {
        background: #08C4EE;
      }
    }
  }

  &__question-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 110.2%;
    color: #242424;
    margin-bottom: 22px;
  }

  &__logo {
    font-size: 62px;
    opacity: 0.1;
    margin: 0 0 0 50px;
  }

  &__button {
    position: absolute;
    width: 167px;
    height: 56px;
    left: 50%;
    bottom: -28px;
    transform: translate(-50%);
    background: #FFF;
    border: 1px solid #08C4EE;
    box-sizing: border-box;
    border-radius: 42px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #08C4EE;
    z-index: 1;

    &:active {
      border: 2px solid #08C4EE;
    }
  }

  &__input {
    height: 34px;
    border-radius: 6px;
    margin: 0 10px 0 0;
  }
}

@media screen and (max-width: 1900px) {
  .application {
    &__number {
      position: absolute;
      top: auto;
      right: auto;
      bottom: -86px;
      left: 0;
    }
  }
}

@media screen and (max-width: 1390px) {
  .application {
    &__photo {
      img {
        transform: translate(130px, -47px);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .application {
    &__container {
      &::before {
        top: -86px;
        left: 100px;
        width: 266px;
        height: 86px;
      }

      &::after {
        width: 266px;
        height: 86px;
        right: 10%;
        bottom: -86px;
      }
    }

    &__photo {
      width: 50%;

      img {
        width: 120%;
        transform: translate(70px, -47px);
      }
    }

    &__froze {
      width: 50%;
      padding: 100px 0 100px 94px;
    }
  }
}

@media screen and (max-width: 992px) {
  .application {
    &__container {
      &::before {
        top: -66px;
        width: 206px;
        height: 66px;
      }

      &::after {
        bottom: -66px;
        width: 206px;
        height: 66px;
        right: 10%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .application {
    &__container {
      &::before {
        width: 56px;
        height: 27px;
        top: -27px;
        right: auto;
      }
      &::after {
        width: 56px;
        height: 27px;
        bottom: -27px;
        right: auto;
        left: 10%;
      }
    }
    &__number {
      top: auto;
      right: 0;
      bottom: -100px;
      left: auto;
    }
    &__wrapper {
      flex-direction: column-reverse;
    }
    &__photo {
      width: 300px;
      top: -200px;
      left: 50%;
      transform: translateX(-30%);

      img {
        width: 100%;
        transform: none;
      }
    }
    &__froze {
      width: 100%;
      padding: 64px 25px;
    }
    &__logo {
      font-size: 50px;
    }
  }
}

@media screen and (max-width: 800px) {
  .application {
    &__wrapper {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 414px) {
  .application {
    min-height: auto;
    padding: 50px 15px 120px;

    &__container {
      &::after {
        width: 30px;
        height: 15px;
        bottom: -15px;
        right: auto;
        left: 12%;
      }
    }

    &__photo {
      width: 200px;
      top: -90px;
      transform: translateX(-40%);

      img {
        width: 120%;
        transform: translate(10%, -92px);
      }
    }

    &__line-stage {
      margin: 0 0 20px -10px;

      button {
        span {
          margin: 0;
        }
      }
    }

    &__button {
      width: 140px;
      height: 46px;
      bottom: -23px;
      z-index: 1;
    }

    &__answer {
      font-size: 14px;
      margin: 0 0 25px 0;

      span {
        font-size: 14px;
      }
    }

    &__froze {
      padding: 45px 25px;
    }

    &__logo {
      font-size: 24px;
    }
  }
}
