.contacts {
    position: relative;
    min-height: 1410px;
    display: flex;
    justify-content: flex-end;
    background: #f6f6f6;
    padding: 0 0 92px 0;
    z-index: 0;

    &__observer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__title {
        margin-bottom: 36px;
    }

    &__line-white {
        position: absolute;
        top: 168px;
        left: 0;
        width: 100%;
        min-height: 220px;
        background: white;
    }

    &__body {
        display: grid;
        grid-template-rows: 297px 194px 194px 74px;
        grid-template-columns: minmax(260px, 1fr) minmax(129px, 1fr) minmax(1175px, 3fr);
        gap: 50px;
    }

    &__address {
        width: 100%;
        background: #fff;
        box-shadow: 20px 20px 60px rgba(14, 14, 15, 10%);
        border-radius: 12px;
        transition: 0.9s all;
        padding: 53px 0 53px 56px;

        &_contact {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;
            margin: 78px 0 0 0;
        }

        &_street {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 3;
        }

        &_time {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 3;
            grid-row-end: 4;
        }

        &_letter,
        &_mail {
            display: flex;
            justify-content: center;
            cursor: pointer;
            padding: 20px;

            .address__title {
                text-align: center;
            }

            &:hover {
                background: #08c4ee;

                .address__title {
                    color: white;
                }

                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }

        &_letter {
            flex-direction: column;
            align-items: center;
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 4;
            grid-row-end: 5;
        }

        &_mail {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 4;
            grid-row-end: 5;
        }
    }

    &__map {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 5;
        position: relative;
        width: 100%;
        height: 100%;

        iframe {
            width: 100%;
            height: 100%;
            cursor: pointer;
            border-radius: 20px;
        }

        &:hover {
            img {
                transform: scale(0);
            }

            iframe {
                z-index: 1;
            }
        }
    }

    &__subtitle {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #222;
    }

    &__text {
        display: block;
        font-size: 16px;
        line-height: 19px;
        margin: 0 0 10px 0;
    }

    &__number {
        top: 200px;
        right: 0;
    }
}

@media screen and (max-width: 1650px) {
    .contacts {
        &__map {
            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 169px;
                left: 235px;
            }
        }
    }
}

@media screen and (max-width: 1390px) {
    .contacts {
        &__map {
            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 169px;
                left: 235px;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .contacts {
        &__map {
            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 169px;
                left: 120px;
            }
        }
    }
}

@media screen and (max-width: 1140px) {
    .contacts {
        min-height: 1200px;
        padding: 0 0 50px 0;

        &__title {
            //width: 240px;
            //height: 245px;
            //padding: 0 0 41px 18px;
            margin-bottom: 42px;

            h2 {
                //font-size: 28px;
                //line-height: 44px;
            }
        }

        &__map {
            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 169px;
                left: 30px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .contacts {
        &__body {
            grid-template-rows: minmax(469px, 1fr) minmax(175px, auto) minmax(155px, auto) minmax(155px, auto) minmax(
                    60px,
                    auto
                );
            grid-template-columns: 2fr 1fr;
            row-gap: 30px;
        }

        &__map {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;
        }

        &__address {
            &_contact {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 3;
                margin: 78px 0 0 0;
            }

            &_street {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 3;
                grid-row-end: 4;
            }

            &_time {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 4;
                grid-row-end: 5;
            }

            &_letter,
            &_mail {
                display: flex;
                justify-content: center;
                padding: 20px;
            }

            &_letter {
                flex-direction: column;
                align-items: center;
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 5;
                grid-row-end: 6;
            }

            &_mail {
                grid-column-start: 2;
                grid-column-end: 3;
                grid-row-start: 5;
                grid-row-end: 6;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .contacts {
        &__address {
            padding: 33px 0 33px 36px;

            &.address__button {
                padding: 33px 36px 33px 36px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .contacts {
        &__address {
            padding: 20px 0 20px 20px;
            &.address__button {
                padding: 20px;
            }
        }
    }
}

@media screen and (max-width: 414px) {
    .contacts {
        &__title {
            h2 {
                font-size: 15px;
                line-height: 16px;
            }
        }
    }
}
