.stt {
    //background-image: url('./../img/top.svg');
    //background-size: cover;
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 50px;
    right: 50px;
    visibility: hidden;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
    &__active {
        visibility: visible;
    }
}

.top-svg {
    fill: #08c4ee;
}
