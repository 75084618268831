.form {
  position: relative;

  &__block {
    margin: 0 0 20px 0;

    &:last-of-type {
      margin: 0 0 30px 0;
    }
  }

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #8B8B8B;
    cursor: pointer;
    margin: 0 0 2px;
    padding: 10px 0;

    textarea {
      max-width: 300px;
      min-width: 210px;
      height: 100px;
      border-radius: 6px;
      resize: none;
      padding: 10px;
    }

    &_flex {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__text {
    &_active {
      color: #0A0A0A;
    }
  }

  &__help {
    color: #0A0A0A;
    margin: 0 8px 0 0;

    &_small {
      display: block;
      font-size: 12px;
      color: #8B8B8B;
      margin: 0 0 5px 0;
    }

    &_disabled {
      color: #8B8B8B;
      cursor: default;
    }
  }

  &__input {
    position: relative;
    border: 1px solid #08C4EE;
    padding: 0 10px;

    &_popup {
      padding: 15px 20px;
      border-radius: 10px;
      margin: 0 0 20px;

      input {
        width: 100%;
        font-size: 16px;
        &::placeholder {
          font-size: 16px;
        }
      }
    }

    &_error {
      &:not(.form__label-policy) {
        border: 1px solid red;
      }
      color: red;
    }

    &[type="radio"], &[type="checkbox"] {
      display: none;

      & + span {
        padding: 0 0 0 26px;

        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 18px;
          height: 18px;
          transition: 0.2s all linear;
          border-radius: 50%;
        }

        &::before {
          border: 1px solid #08C4EE;
        }

        &::after {
          border: 4px solid #08C4EE;
          transform: scale(0);
        }
      }

      &:checked {
        & + span {
          &::after {
            transform: scale(1) translateY(-50%);
          }
        }
      }
    }

    &_number {
      width: 60px;
      height: 37px;
      margin: 0 10px;
    }

    &_disabled {
      &:disabled {
        cursor: default;
      }
    }
  }

  &__input-error {
    display: none;

    &_active {
      font-size: 12px;
      position: absolute;
      left: 0;
      bottom: -20px;
      display: block;
      color: red;
      margin: 3px 0 0 0;
    }
  }

  &__text-error {
    display: none;

    &_active {
      position: absolute;
      bottom: -5px;
      left: 0;
      font-size: 13px;
      color: red;
    }
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__metre {
    position: relative;
    font-size: 14px;
    color: #767676;

    &::before {
      content: '2';
      display: block;
      position: absolute;
      top: -6px;
      right: -8px;
      font-size: 14px;
      color: #767676;
    }
  }

  &__btn {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    width: 50px;
    height: 40px;
    opacity: 1;
    cursor: pointer;
    background: transparent;
    border: 1px solid rgba(8, 196, 238, 0.57);
    transition: .4s background-color;

    span {
      animation: minmax 2s infinite;
    }

    &:hover {
      background: rgba(8, 196, 238, 0.57);

    }

    &_inactive {
      opacity: .2;
      cursor: default;
      background: transparent;
      span {
        animation: none;
      }
      span {
        &:hover {
          animation: none;
        }
      }
      &:hover {
        background: transparent;
        cursor: default;
      }
    }
  }

  &__error {
    display: none;
  }

  &__label-policy {
    display: none;
    margin-bottom: 20px;
    font-size: 10px;

    &_noindent {
      margin-bottom: 0;
    }
  }

  &__policy {
    margin: 0 6px 0 0;
  }

  &__policy-link {
    color: #08c4ee;
    font-size: 12px;

    &:hover {
      color: grey;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1024px) {
  .form {
    &__btn {
      height: 40px;
    }
  }
}
