.crumbs {
  position: absolute;
  bottom: -18px;
  left: 0;
  &__item {
    font-size: 12px;
    color: #737373;
    &_active {
      font-size: 12px;
      color: #BEBEBE;
      &:hover {
        border-bottom: 1px solid #08c4ee;
      }
    }
    &:hover {
      border-bottom: 1px solid #08c4ee;
    }
  }
}

@media screen and (max-width: 1390px) {
  .crumbs {
    left: 3%;
  }
}
