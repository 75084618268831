.player {
  padding: 56.25% 0 0 0;
}

@media screen and (max-width: 1024px) {
  .player {
    &__track {
      width: 50%;
    }

    &__fullscreen {
      width: 20px;
      height: 20px;
    }

    &__play {
      width: 60px;
      height: 60px;
    }
  }
}

@media screen and (max-width: 576px) {
  .player {
    &__time {
      font-size: 12px;
    }
    &__play-track {
      width: 10px;
      height: 10px;
    }
    &__track {
      width: 40%;
    }
    &__fullscreen {
      width: 18px;
      height: 18px;
    }
    &__sound {
      display: flex;
      align-items: flex-end;
      width: 31px;
      height: 15px;

      span {
        width: 2px;
        &:nth-of-type(1) {
          height: 4px;
        }

        &:nth-of-type(2) {
          height: 6px;
        }

        &:nth-of-type(3) {
          height: 8px;
        }

        &:nth-of-type(4) {
          height: 10px;
        }

        &:nth-of-type(5) {
          height: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .player {
    &__time {
      font-size: 10px;
    }
    &__track {
      width: 30%;
    }
    &__play {
      width: 50px;
      height: 50px;
    }
  }
}
