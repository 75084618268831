.number {
  width: 265px;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000;
  position: absolute;
  background: #fff;
  box-shadow: 20px 20px 60px rgba(14, 14, 15, 9%);
}

@media screen and (max-width: 800px) {
  .number {
    width: 71px;
    height: 87px;
  }
}
