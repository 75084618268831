.container {
  position: relative;
  width: 1390px;
  height: 100%;
  margin: 0 auto;
  &_right {
    width: calc(1390px + ((100vw - 1390px) / 2));
    height: 100%;
    margin: 0;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    width: 1280px;
    &_right {
      width: calc(1280px + ((100vw - 1280px) / 2));
    }
  }
}

@media screen and (max-width: 1390px) {
  .container {
    width: 100%;
    padding: 0 3%;
  }
}
